var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picker-video"},[_c('div',{staticClass:"picker-video-hd"},[_c('div',{staticClass:"btn-actions"},[_c('el-upload',{staticClass:"btn-upload",attrs:{"action":"","accept":"video/mp4","show-file-list":false,"http-request":_vm.handleUpload,"before-upload":_vm.beforeAvatarUpload,"on-success":_vm.handleAvatarSuccess,"on-error":_vm.uploadError}},[_c('el-button',[_vm._v("上传视频")])],1)],1)]),_c('div',{staticClass:"picker-video-bd"},[_c('div',{staticClass:"rg-container"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"image-list"},[_vm._l((_vm.list),function(item,index){return _c('div',{key:`image-item-wrap__${index}`,staticClass:"image-item-wrap"},[_c('div',{staticClass:"image-item",style:({ color: '#fff' })},[_c('video-player',{staticClass:"picker-video-player",attrs:{"options":{
                // preload: 'auto',
                aspectRatio: '16:9',
                fluid: true,
                sources: [
                  {
                    type: item.image_type,
                    src: item.url
                  }
                ],
                notSupportedMessage: '此视频暂无法播放，请稍后再试',
                controlBar: false
              }}}),_c('div',{staticClass:"image-modal",on:{"click":function($event){return _vm.handleClickItem(item)}}},[_c('i',{staticClass:"iconfont icon-link",on:{"click":function($event){$event.stopPropagation();return _vm.handleCopy(item.url)}}})])],1),_c('div',{staticClass:"image-title-wrap",attrs:{"title":item.image_name}},[_c('p',{staticClass:"image-title-wrap__title"},[_vm._v(" "+_vm._s(item.image_name)+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.multiple ? _vm.isActive(item) > -1 : _vm.isActive(item)),expression:"multiple ? isActive(item) > -1 : isActive(item)"}],staticClass:"image-box-selected"},[_c('div',{staticClass:"image-box-selected__right-angle"}),_c('div',{staticClass:"image-box-selected__text"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.multiple),expression:"multiple"}]},[_vm._v(_vm._s(_vm.isActive(item) + 1))]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.multiple),expression:"!multiple"}],staticClass:"iconfont icon-check"})])])])}),(_vm.list.length == 0)?_c('el-empty',{attrs:{"description":"暂无数据"}}):_vm._e()],2),_c('el-pagination',{attrs:{"layout":"total, prev, pager, next","current-page":_vm.pageCur,"page-size":_vm.pageSize,"total":_vm.pageCount},on:{"update:currentPage":function($event){_vm.pageCur=$event},"update:current-page":function($event){_vm.pageCur=$event},"current-change":_vm.goPage}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }